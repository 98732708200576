// stores/filterStore.js
import { defineStore } from 'pinia';

export const useFilterStore = defineStore('filterStore', {
  state: () => ({
    filterParams: {
      type: [],
      maker: '',
      makerCar: '',
      condition: [],
      modelYear: '',
      mileage: null,
      keyword: '',
      sortOrder: 'newest',
    },
    modalFilter: {
      type: [],
      modelYear: '',
      makerCar: '',
      condition: [],
      year: '',
      mileage: null,
      sortOrder: 'newest',
    },
    listFilter: {
      type: [],
    },
    query: null,
    reset: false,
    keyword: '',
    mileage: {
      label: '',
      value: null,
    },
    type: [],
  }),
  actions: {
    setFilterParams(newParams) {
      this.filterParams = newParams;
    },
    resetFilterParams() {
      this.reset = true,
      this.filterParams = {
        type: [],
        maker: '',
        makerCar: '',
        condition: [],
        year: '',
        mileage: null,
        sortOrder: 'newest',
      },
      this.query = null
    },
    resetFilterParamsList() {
      this.filterParams = {
        maker: '',
        makerCar: '',
        condition: [],
        year: '',
        mileage: null,
        sortOrder: 'newest',
      }
    },
    resetModalFilter() {
      this.modalFilter = {
        type: [],
        modelYear: '',
        makerCar: '',
        condition: [],
        year: '',
        mileage: null,
        sortOrder: 'newest',
      }
    },
    resetListFilter() {
      this.listFilter = {
        type: [],
      }
    },
  }
});
