/**============================================================
*   [footer__page-top]
*   description : ページトップボタン表示非表示・位置設定の処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  const footerBtn = document.getElementById('js-footer__page-top');
  // let footerH;
  if(footerBtn){
    //表示非表示
    window.addEventListener('scroll', () => {
      footerH = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--footer-H'));
      if( 20 <= window.scrollY ) {
        footerBtn.classList.add('is-show');
      } else {
        footerBtn.classList.remove('is-show');
      }
      if(document.documentElement.scrollHeight - window.scrollY - window.innerHeight <= 10) {//footerH - 56
        footerBtn.classList.add('is-absolute');
      }else {
        footerBtn.classList.remove('is-absolute');
      }
    });

    //scroll
    footerBtn.addEventListener('click', () =>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }
});
