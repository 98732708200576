//import
import './build/setVers.js';
import './build/anchor.js';
import './build/pagetop.js';
import './build/contactForm.js';
import { createApp } from 'vue';

/**============================================================
*   [Animation] observerでアニメーション用クラス付与
*   target : .p-animation-observer
============================================================*/
import observerShowObj from './build/observerShowObj';
const observerElem = document.getElementsByClassName('p-animation-observer');
const createObserveObj = () => {
  if (!observerElem.length) return;
  for (let i = 0; i < observerElem.length; i++) {
    new observerShowObj(observerElem[i]);
  }
}
document.addEventListener('DOMContentLoaded', createObserveObj, false);

/**============================================================
*   [共通] 高さ揃え
*   targetコンテナ : js-fix-height-box
============================================================*/
import FixHeightBox from './build/FixHeightBox';
const FixHeightBoxElem = document.getElementsByClassName('js-fix-height-box');
const createFixHeightBoxObj = () => {
  if (!FixHeightBoxElem.length) return;
  for (let i = 0; i < FixHeightBoxElem.length; i++) {
    new FixHeightBox(FixHeightBoxElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createFixHeightBoxObj, false);

/**============================================================
*   [共通] アコーディオングループ
*   target : .js-toggle-accordion
============================================================*/
import ToggleAccordion from './build/ToggleAccordion.vue';
const ToggleAccordionElem = document.getElementsByClassName('js-toggle-accordion');
const createToggleAccordionObj = () => {
  if (!ToggleAccordionElem.length) return;
  for (let i = 0; i < ToggleAccordionElem.length; i++) {
    createApp(ToggleAccordion).mount(ToggleAccordionElem[i]);
  }
}
document.addEventListener('DOMContentLoaded', createToggleAccordionObj, false);

/**============================================================
*   [viewport] 375px以下画面は縮小
============================================================*/
(function () {
  const viewport = document.querySelector('meta[name="viewport"]');
  function switchViewport() {
    const value =
      window.outerWidth >= 375
        ? 'width=device-width,initial-scale=1'
        : 'width=375';
    if (viewport.getAttribute('content') !== value) {
      viewport.setAttribute('content', value);
    }
  }
  addEventListener('resize', switchViewport, false);
  switchViewport();
})();

/**============================================================
*   [共通] パネル型記事スライダー
*   target : .p-archive-slider
============================================================*/
import ArchiveSlider from './build/ArchiveSlider';
const ArchiveSliderElem = document.getElementsByClassName('p-archive-slider');
const createArchiveSliderObj = () => {
  if (!ArchiveSliderElem.length) return;
  for (let i = 0; i < ArchiveSliderElem.length; i++) {
    new ArchiveSlider(ArchiveSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createArchiveSliderObj, false);

/**============================================================
*   [記事詳細] メインスライダー
*   target : .p-post-main-slider
============================================================*/
import PageMainSlider from './build/PageMainSlider';
const PageMainSliderElem = document.getElementsByClassName('p-post-main-slider');
const createPageMainSliderObj = () => {
  if (!PageMainSliderElem.length) return;
  for (let i = 0; i < PageMainSliderElem.length; i++) {
    new PageMainSlider(PageMainSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createPageMainSliderObj, false);

/**============================================================
*   [form] select
*   description : selectのvalueが空の時にテキスト色を変更する処理を記述
============================================================*/
const selectIsEmpty = () => {
  if(document.getElementsByTagName('select').length > 0){
    const selects = document.getElementsByTagName('select');
    [...selects].forEach(select => {
      if (select.value !== '') {
        select.classList.remove('is-value-empty');
      }else{
        select.classList.add('is-value-empty');
      }
      select.addEventListener('change', (e) => {
        console.log(select);
        if (e.target.value !== '') {
          select.classList.remove('is-value-empty');
        }else{
          select.classList.add('is-value-empty');
        }
      })
    })
  }
}

document.addEventListener('DOMContentLoaded', selectIsEmpty, false);

/**============================================================
*   [form] agree-checkbox
*   description : チェックボックス後ボタンの活性・非活性を変更する処理を記述
============================================================*/
if(document.getElementById('js-agree')){
  const policyCheck = createApp({
    data() {
      return {
        isChecked: false,
      }
    }
  })
  policyCheck.mount('#js-agree');
}
/**============================================================
*   iPhone分岐用クラス付与
============================================================*/
if (navigator.userAgent.indexOf('iPhone') > 0) {
  let body = document.documentElement;
  body.classList.add('is-iphone');
}
/**============================================================
*   [form]
*   description : ファイル選択処理
============================================================*/
const uploads = document.getElementsByClassName('c-form-upload');

if (uploads) {
  [...uploads].forEach(target => {
    //選択ファイル名を表示
    const inputButton = target.querySelector('.js-form-upload');
    const textArea = target.querySelector('.text');
    textArea.classList.add('is-empty');
    inputButton.addEventListener('change', () => {
      textArea.textContent = inputButton.files[0].name;
      textArea.classList.remove('is-empty');
    });
    //選択ファイルを削除
    const deleteButton = target.querySelector('.js-form-delete');
    deleteButton.addEventListener('click', () => {
      inputButton.value = '';
      textArea.textContent = '選択してください';
      textArea.classList.add('is-empty');
    });
  });
}

/**============================================================
*   [vue KaitoriList]
*   description : 買取実績一覧
============================================================*/
import { createPinia } from 'pinia';
import KaitoriList from './build/KaitoriList.vue';
import ModalSearch from './build/ModalSearch.vue';
import KeywordSearch from './build/KeywordSearch.vue';

const pinia = createPinia();

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#kaitori-list')) {
    createApp(KaitoriList).use(pinia).mount('#kaitori-list');
  }

  if (document.querySelector('#vue-modal-search')) {
    createApp(ModalSearch).use(pinia).mount('#vue-modal-search');
  }

  if (document.querySelector('#vue-keyword-search')) {
    createApp(KeywordSearch).use(pinia).mount('#vue-keyword-search');
  }
});
