/**============================================================
*   [setVers]
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  const headerElem = document.getElementById('header-navigation');
  const footerElem = document.getElementById('footer-navigation');
  const topicPathElem = document.querySelector('.p-nav-topic-path');
  const logoSlider = document.querySelector('.p-top-record');

  //ヘッダーの高さ
  if(headerElem !== null){
    window.addEventListener('load', setHeaderHeight, true);
    window.addEventListener('resize', setHeaderHeight, true);
  }
  function setHeaderHeight() {
    document.documentElement.style.setProperty('--header-H', headerElem.getBoundingClientRect().height+'px');
  }

  //フッターの高さ
  if(footerElem !== null){
    window.addEventListener('load', setFooterHeight, true);
    window.addEventListener('resize', setFooterHeight, true);
  }
  function setFooterHeight() {
    document.documentElement.style.setProperty('--footer-H', footerElem.getBoundingClientRect().height+'px');
  }

  //パンくずの高さ
  if(topicPathElem !== null){
    window.addEventListener('load', setTopicPathHeight, true);
    window.addEventListener('resize', setTopicPathHeight, true);
  }
  function setTopicPathHeight() {
    document.documentElement.style.setProperty('--topic-path-H', topicPathElem.getBoundingClientRect().height+'px');
  }

  //ロゴスクロールアニメーションの移動量
  if(logoSlider !== null){
    window.addEventListener('load', setLogoSliderWidth, true);
  }
  function setLogoSliderWidth() {
    let calcNum = logoSlider.getAttribute('data-loop-num');
    let loopElem = logoSlider.getElementsByTagName('img');
    let loopW = 0;
    for (let i = 0; i < calcNum; i++) {
      loopW += [...loopElem][i].getBoundingClientRect().width;
    }
    loopW = loopW + 56 * calcNum//gap
    document.documentElement.style.setProperty('--offset-width', -loopW+'px');
  }

});
