/**============================================================
*   [共通] ToggleAccordion
*   description : アコーディオン
============================================================*/
<script>
import {onMounted, reactive, watchEffect} from 'vue';
import { createMedia } from 'vue-use-media';
export default {
  name: 'ToggleAccordion',
  setup() {
    const media = createMedia({
      isDesktop: 'screen and (min-width: 960px)'
    })
    const state = reactive({
      openNum: [],
    })

    const resetCategory = function() {
      state.openNum = [];
      if(media.isDesktop){
        //PC時のデフォルト状態
        state.openNum = ["all"];
      }else{
        //SP時のデフォルト状態
        state.openNum = [];
      }
    }

    onMounted(() => {
      //file upload適用
      const uploads = document.getElementsByClassName('c-form-upload');
      if (uploads) {
        [...uploads].forEach(target => {
          //選択ファイル名を表示
          const inputButton = target.querySelector('.js-form-upload');
          const textArea = target.querySelector('.text');
          textArea.classList.add('is-empty');
          inputButton.addEventListener('change', () => {
            textArea.textContent = inputButton.files[0].name;
            textArea.classList.remove('is-empty');
          });
          //選択ファイルを削除
          const deleteButton = target.querySelector('.js-form-delete');
          deleteButton.addEventListener('click', () => {
            inputButton.value = '';
            textArea.textContent = '選択してください';
            textArea.classList.add('is-empty');
          });
        });
      }
      //初期化
      watchEffect(() => {
        resetCategory();
      })

    })

    //子階層の開閉
    //指定のカテゴリ名を受け取って表示：初期値は0（非表示）
    const toggleTarget = (num)=> {
      state.isStopAnimation = false;
      if(String(state.openNum).includes(num)){
        let result = state.openNum;
        result = result.filter((item) => {
          return (item != num);
        });
        state.openNum = result;
      }else{
        state.openNum.push(num);
      }
    }

    return {
      media,
      state,
      toggleTarget,
      resetCategory,
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.height = '0'
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`
    },
    leave(el) {
      el.style.height = '0'
    },
  }
}
</script>

