/**============================================================
*   [anchor]
*   description : ページ内リンクの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  let header = document.getElementById('header-navigation');
  let headerHeight = (header) ? header.offsetHeight : 0;

  //headerの高さ算出
  const mqlAnchor = window.matchMedia('(min-width: 768px)');
  function mqlAnchorCheck() {
    headerHeight = (header) ? header.offsetHeight : 0;
  }
  mqlAnchorCheck(mqlAnchor);
  mqlAnchor.addEventListener('change', mqlAnchorCheck, false);

  function scrollHashSection() {
    const targetId = window.location.hash.replace('#','');
    if(targetId){
      const targetElement = document.getElementById(targetId);
      const targetElementSection = document.getElementById(targetId + '-section');
      let targetOffsetTop;
      if(targetElementSection){
        targetOffsetTop = window.scrollY + targetElementSection.getBoundingClientRect().top;
        window.history.replaceState(null, '', window.location.pathname + window.location.search);
      }else if(targetElement){
        targetOffsetTop = window.scrollY + targetElement.getBoundingClientRect().top;
        window.history.replaceState(null, '', window.location.pathname + window.location.search);
      }
      let totalScrollAmount = targetOffsetTop - headerHeight;
      window.scrollTo({
        top: totalScrollAmount,
        behavior: 'smooth'
      });
    }

  }
  //ロード時：ハッシュがある場合該当箇所へ移動
  window.addEventListener('load', scrollHashSection);
  window.addEventListener('hashchange', scrollHashSection);
});
