<template>
  <div
    v-show="!dataLoaded"
    class="p-archive-loading"
  >
    <div class="p-archive-loader" />
  </div>
  <div v-show="dataLoaded">
    <div class="l-container">
      <section class="p-nav-search-panel l-box l-box--no-shadow l-box--radius-20 u-bg--key-light-2">
        <div class="p-nav-search-panel__inner">
          <div class="c-title-icon">
            <i
              class="c-icon c-icon--reflect material-symbols-outlined u-fz--title-sm"
              aria-hidden="true"
            >local_shipping</i>
            <h2 class="text c-text-md u-weight--bold">
              車両タイプ
            </h2>
          </div>
          <div class="p-nav-search-panel__body">
            <span
              v-for="typeOption in typeOptions"
              :key="typeOption"
              class="c-form-checkbox"
            >
              <label>
                <input
                  type="checkbox"
                  name="type"
                  :value="typeOption"
                  :checked="store.filterParams.type.includes(typeOption)"
                  @change="() => updateType(typeOption)"
                >
                <span class="c-form-checkbox__icon">
                  <i
                    class="c-icon material-symbols-outlined"
                    aria-hidden="true"
                  >done</i>
                </span>{{ typeOption }}
              </label>
            </span>
          </div>
        </div>
        <div class="l-gap--sm p-nav-search-panel__buttons">
          <div class="p-nav-search-panel__search-button">
            <button
              type="button"
              class="c-button c-button--s"
              @click="applyFilters()"
            >
              <span
                class="c-icon left-xxs"
                aria-hidden="true"
              >
                <i
                  class="material-symbols-outlined u-fz--title-sm"
                  aria-hidden="true"
                >search</i>
              </span>
              検索する
            </button>
          </div>
          <div class="p-nav-search-panel__modal-button">
            <button
              type="button"
              class="c-link-text-icon u-weight--bold u-color--key"
              data-micromodal-trigger="modal-search"
            >
              <span class="text c-text-xs">詳しい条件で検索する</span>
              <span class="c-icon c-icon-plus-circle c-icon-plus-circle--s" />
            </button>
          </div>
        </div>
      </section>
    </div>
    <div class="l-gap--lg">
      <div class="l-container">
        <section>
          <div class="d-flex flex-wrap l-grid-gap--xxs justify-content-between align-items-center">
            <h2 class="c-title-md u-color--key">
              <span
                v-if="store.filterParams.keyword"
              >「{{ store.filterParams.keyword }}」で検索した結果</span>
              <span v-else>{{ searchResultText }}</span>
              <span class="u-display--ib">（{{ data?.length }}件）</span>
            </h2>
            <div class="p-nav-sort">
              <span class="text u-visible--sm">並び替え</span>
              <div class="c-form-select c-form-select--thin">
                <select
                  v-model="store.filterParams.sortOrder"
                  name="maker-type"
                >
                  <option
                    value="newest"
                    selected
                  >
                    新着順
                  </option>
                  <option value="priceHigh">
                    価格が高い
                  </option>
                  <option value="priceLow">
                    価格が安い
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="l-gap--xs">
            <div
              class="l-grid-row-2--sm-down l-grid-row-3--sm js-fix-height-box"
              data-each-num-md="3"
              data-each-num-sm="3"
            >
              <template
                v-for="post in posts"
                :key="post"
              >
                <article class="p-archive-item">
                  <a :href="post.url">
                    <figure class="p-archive-item__thumb">
                      <img
                        :src="post.image"
                        alt=""
                        width="340"
                        height="220"
                        loading="lazy"
                      >
                      <ul class="c-list-label p-archive-item__label">
                        <li>
                          <div class="c-icon-label">
                            <span
                              class="c-icon"
                              aria-hidden="true"
                            >
                              <svg :class="'svg-icon_type_' + post.typeSlug + '-dims'">
                                <use :xlink:href="'/cms/wp-content/themes/unitrucks/assets/img/shared/sprite.symbol.svg#icon_type_' + post.typeSlug" />
                              </svg>
                            </span>
                            <span class="text">{{ post.type }}</span>
                          </div>
                        </li>
                      </ul>
                    </figure>
                    <div class="p-archive-item__body">
                      <div class="p-archive-item__title">
                        <h2 class="c-text-lg u-weight--bold">
                          <span
                            class="d-block js-fix-height-box__item"
                            data-height-group="2"
                          >［{{ post.maker }}］{{ post.makerCar }}</span>
                        </h2>
                      </div>
                      <div class="l-gap--xxs d-flex flex-direction-column">
                        <p class="c-text-xs u-color--gray">{{ post.model }}<span v-if="post.year">（{{ post.year }}）</span></p>
                      </div>
                      <div class="u-mt--xxs">
                        <div class="p-archive-item__purchase-price">
                          <span class="title">買取<br>金額</span>
                          <span class="num"><span>¥</span>{{ post.price }}<span>-</span></span>
                        </div>
                      </div>
                      <div class="l-gap--xxs d-flex flex-wrap l-grid-gap--xxs justify-content-between align-items-end">
                        <div class="p-archive-item__distance u-color--gray">
                          <span
                            class="c-icon"
                            aria-hidden="true"
                          >
                            <i
                              class="material-symbols-outlined"
                              aria-hidden="true"
                            >swap_driving_apps_wheel</i>
                          </span>
                          <span
                            v-if="post.mileage"
                            class="num"
                          >
                            {{ post.mileage }}km
                          </span>
                          <span
                            v-else
                            class="c-text-sm"
                          >
                            走行距離不明
                          </span>
                        </div>
                        <p
                          v-if="post.PurchasePeriod"
                          class="c-text-xxs u-color--gray"
                        >{{ post.PurchasePeriod }} 買取</p>
                      </div>
                    </div>
                  </a>
                </article>
              </template>
            </div>
          </div>
        </section>
      </div>
      <div class="l-container">
        <nav class="p-nav-pager l-gap--lg">
          <ul>
            <li
              class="p-nav-pager__prev"
            >
              <button
                aria-label="前へ"
                :disabled="meta.current_page.value === 1"
                @click="prevPage"
              >
                <span
                  class="c-icon c-icon-arrow-circle"
                  aria-hidden="true"
                >
                  <svg class="svg-icon_arrow-dims">
                    <use xlink:href="/cms/wp-content/themes/unitrucks/assets/img/shared/sprite.symbol.svg#icon_arrow" />
                  </svg>
                </span>
                前のページへ
              </button>
            </li>
            <template
              v-for="(pageNumber) in pageNumbers"
              :key="pageNumber"
            >
              <li
                :class="{ 'is-current': pageNumber === meta.current_page.value }"
              >
                <button
                  v-if="typeof pageNumber != 'string'"
                  @click="goToPage(pageNumber)"
                >
                  <span>{{ pageNumber }}</span>
                </button>
                <span v-else>{{ pageNumber }}</span>
              </li>
            </template>
            <li class="p-nav-pager__next">
              <button
                aria-label="次へ"
                :disabled="meta.current_page.value === meta.last_page.value || meta.last_page.value === 0"
                @click="nextPage"
              >
                次のページへ
                <span
                  class="c-icon c-icon-arrow-circle"
                  aria-hidden="true"
                >
                  <svg class="svg-icon_arrow-dims">
                    <use xlink:href="/cms/wp-content/themes/unitrucks/assets/img/shared/sprite.symbol.svg#icon_arrow" />
                  </svg>
                </span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from 'vue';
import axios from 'axios';
import { useFilterStore } from './store';

const store = useFilterStore();

// 絞り込みの選択肢
const typeOptions = ref();

const originalData = ref();
const data = ref();
const posts = ref();
const dataLoaded = ref(false);

const searchResultText = ref();

// 絞り込み条件

// 車両タイプの変更
const updateType = (value) => {
  const index = store.listFilter.type.indexOf(value);
  if (index > -1) {
    // Value exists in the array, remove it
    store.listFilter.type.splice(index, 1);
  } else {
    // Value does not exist, add it
    store.listFilter.type.push(value);
  }
};

const sortData = (data, sortOrder) => {
  switch (sortOrder) {
    case 'newest':
      return data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    case 'priceHigh':
      return data.sort((a, b) => b.price_num - a.price_num);
    case 'priceLow':
      return data.sort((a, b) => a.price_num - b.price_num);
    default:
      return data;
  }
};

// 絞りこみ検索
const filteredData = () => {
  let filtered = originalData.value;

  if (store.filterParams.type.length > 0) {
    filtered = filtered.filter(item => store.filterParams.type.some(selectedType => item.type.includes(selectedType)));
  }

  if (store.filterParams.maker) {
    filtered = filtered.filter(item => item.maker === store.filterParams.maker);
  }

  if (store.filterParams.makerCar) {
    filtered = filtered.filter(item => item.makerCar === store.filterParams.makerCar);
  }

  if (store.filterParams.condition.length > 0) {
    filtered = filtered.filter(item => store.filterParams.condition.some(selectedCondition => item.condition.includes(selectedCondition)));
  }

  let selectedMileageValue = store.filterParams.mileage;

    if(selectedMileageValue) {
      if (typeof selectedMileageValue === 'number') {
        if (selectedMileageValue === 1000001) {
          // 1300001 の場合の特別な処理
          filtered = filtered.filter(item => item.mileage_num > 1000000);
        } else {
          filtered = filtered.filter(item => item.mileage_num > 0 && item.mileage_num <= selectedMileageValue);
        }
      } else if (selectedMileageValue === '不明') {
        // 不明の場合の特別な処理
        filtered = filtered.filter(item => item.mileage === '');
      }
    }

  let selectedYear = store.filterParams.modelYear;

  if (selectedYear) {
    filtered = filtered.filter(item => {
      let itemYear = item.year;

      if (selectedYear === '年式不明') {
        return !itemYear || itemYear === '年式不明';
      }
      if (selectedYear.includes('以前')) {
        let selectedYearEra = selectedYear.split('年')[0].substring(0, 2); // 元号（例："平成"）
        let selectedYearValue = parseInt(selectedYear.split('年')[0].substring(2), 10); // 年（例：15）

        let itemYearEra = itemYear.split('年')[0].substring(0, 2); // アイテムの元号
        let itemYearValue = parseInt(itemYear.split('年')[0].substring(2), 10); // アイテムの年

        if (selectedYearEra === '平成') {
          if (itemYearEra === '平成' && itemYearValue <= selectedYearValue) {
            return true;
          }
          if (itemYearEra === '昭和') {
            return true;
          }
        }

        return false;
      } else {
        return itemYear === selectedYear;
      }
    });
  }
  let keyword = store.filterParams.keyword;
  if (keyword) {
    filtered = filtered.filter(item => {
    const itemText = Object.values(item).filter(value => typeof value === 'string').join(' ');
    return itemText.includes(keyword);
  });
  }
  let sortOrder = store.filterParams.sortOrder;
  if(sortOrder) {
    sortData(filtered, sortOrder);
  }

  data.value = filtered;
  meta.current_page.value = 1;
  updatePosts();
};

// 絞り込みリセット
const resetData = () => {
  store.resetFilterParams();
  data.value = originalData.value;
  const urlWithoutQuery = window.location.pathname;
  window.history.pushState({}, '', urlWithoutQuery);
  updatePosts();
}

// ページネーション
const meta = {
  current_page: ref(1),
  from: ref(null),
  to: ref(null),
  last_page: ref(null),
  per_page: ref(15),
  total: ref(),
};

// 現在のページに合わせて投稿データを更新
const updatePosts = () => {
  meta.from.value = (meta.current_page.value - 1) * meta.per_page.value;
  meta.to.value = meta.from.value + meta.per_page.value;
  posts.value = data.value.slice(meta.from.value, meta.to.value);
  meta.total.value = data.value.length;
  meta.last_page.value = Math.ceil(meta.total.value / meta.per_page.value);
};
// ページ番号のリストを生成
const pageNumbers = computed(() => {
  const currentPage = meta.current_page.value;
  const totalPages = meta.last_page.value;
  let pageRange = [];    // ページネーションの配列
  let pageSizes = 7;     // ページネーションの数
  let centeredPages = 1; // 現在のページの前後
  if(isMobile.value) {
    pageSizes = 5;
    centeredPages = 0;
  }

  let omissionSizes = pageSizes  - 2;  // 省略文字の数
  let lastSizes = totalPages - omissionSizes;

  if( pageSizes < totalPages) {
    if( currentPage + centeredPages <= omissionSizes ){
        for( let i = 1; i <= omissionSizes; i++) {
          pageRange.push(i);
        }
        pageRange.push('...');
        pageRange.push(totalPages);

    } else if(lastSizes < currentPage - centeredPages) {
      pageRange.push(1);
      pageRange.push('...');
      for( let i = lastSizes + 1; i <= totalPages; i++) {
        pageRange.push(i);
      }
    } else {
      pageRange.push(1);
      pageRange.push('...');
      for(let i = currentPage - centeredPages; i < currentPage; i++) {
        pageRange.push(i);
      }
      pageRange.push(currentPage);
      for(let i = currentPage + 1; i <= currentPage + centeredPages; i++) {
        pageRange.push(i);
      }
      pageRange.push('...');
      pageRange.push(totalPages);
    }
  } else {
    for(let i = 1; i <= totalPages; i++) {
      pageRange.push(i);
    }
  }
  return pageRange;
});

const isMobile = ref(window.innerWidth <= 767);

const checkWindowSize = () => {
  isMobile.value = window.innerWidth <= 767;
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

// ページ 前へ
const prevPage = () => {
  // 前のページに移動
  if (meta.current_page.value > 1) {
    meta.current_page.value--;
    updatePosts();
    scrollToTop();
  }
};

// ページ 次へ
const nextPage = () => {
  // 次のページに移動
  if (meta.current_page.value < meta.last_page.value) {
    meta.current_page.value++;
    updatePosts();
    scrollToTop();
  }
};

// ページ ナンバーへ
const goToPage = (pageNumber) => {
  meta.current_page.value = pageNumber;
  updatePosts();
  scrollToTop();
};

// API
const params = {};

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  }
});

const apiGet = async () => {
  const response = await api.get('/api/', { params });
  data.value = response.data.posts;
  originalData.value = response.data.posts;
  typeOptions.value = response.data.meta.type;
  meta.total.value = data.value.length;
  meta.last_page.value = Math.ceil(meta.total.value / meta.per_page.value);
};

const toQueryString = (params) => {
  const parts = [];

  for (const key in params) {
    let value = params[key];
    if (Array.isArray(value)) {
      // 配列の場合はカンマで結合する
      value = value.join(',');
    }
    if (value) {
      // キーと値をエンコードして配列に追加
      parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }

  return parts.join('&');
}

const applyFilters = async () => {
  store.keyword = '';
  await store.resetFilterParams();
  store.filterParams.type = store.listFilter.type;
  store.query = toQueryString(store.filterParams);
  if (window.location.pathname !== '/kaitori/') {
    // 一覧ページ以外からのアクセスの場合、ページ全体をリダイレクト
    window.location.href = `/kaitori/?${store.query}`;
  } else {
    // すでに一覧ページにいる場合、クエリパラメータを更新
    const newUrl = `${window.location.pathname}?${store.query}`;
    window.history.pushState({}, '', newUrl);
  }
  store.resetModalFilter();
}

const processFilterParams = (filterParams) => {
  const keys = Object.keys(filterParams).filter(key => key !== 'sortOrder');

  let nonEmptyKeys = [];
  keys.forEach(key => {
    const value = filterParams[key];
    if (Array.isArray(value)) {
      if (value.length > 0) {
        nonEmptyKeys.push(key);
      }
    } else if (value && value !== '' && value !== null) {
        nonEmptyKeys.push(key);
    }
  });

  let resultTxt = '';
  if (nonEmptyKeys.length >= 2) {
    if(nonEmptyKeys[0] === 'maker' && nonEmptyKeys[1] === 'makerCar' ) {
      resultTxt = `メーカー「${filterParams.maker}」 車種「${filterParams.makerCar}」他の買取実積`;
    } else {
      resultTxt = '検索条件に沿った買取実積';
    }
  } else if (nonEmptyKeys.length === 1) {
    if(nonEmptyKeys[0] === 'type' && filterParams.type.length >= 2 ) {
      resultTxt = `車両タイプ「${filterParams.type[0]}」他の買取実積`;
    } else if(nonEmptyKeys[0] === 'type' && filterParams.type.length === 1) {
      resultTxt = `車両タイプ「${filterParams.type[0]}」の買取実積`;
    }
    if(nonEmptyKeys[0] === 'maker' && filterParams.maker) {
      resultTxt = `メーカー「${filterParams.maker}」の買取実積`;
    }
    if(nonEmptyKeys[0] === 'makerCar' && filterParams.makerCar) {
      resultTxt = `車種「${filterParams.makerCar}」の買取実積`;
    }
    if(nonEmptyKeys[0] === 'condition' && filterParams.condition.length >= 2 ) {
      resultTxt = `状態別「${filterParams.condition[0]}」他の買取実積`;
    } else if(nonEmptyKeys[0] === 'condition' && filterParams.condition.length === 1){
      resultTxt = `状態別「${filterParams.condition[0]}」の買取実積`;
    }
    if(nonEmptyKeys[0] === 'modelYear' && filterParams.modelYear) {
      resultTxt = `年式「${filterParams.modelYear}」の買取実積`;
    }
    if (nonEmptyKeys[0] === 'mileage' && filterParams.mileage) {
      const matchedMileage = store.mileage.find(item => item.mileage === filterParams.mileage);
      const mileageLabel = matchedMileage ? matchedMileage.name : filterParams.mileage;
      resultTxt = `走行距離「${mileageLabel}」の買取実積`;
    }
  } else {
    resultTxt = '最近の買取実績';
  }
  return resultTxt;
};

watch(() => store.query, () => {
  filteredData();
  searchResultText.value = processFilterParams(store.filterParams);
}, { deep: true });

watch(() => store.reset, () => {
  if(store.reset === true) {
    resetData();
    store.reset = false;
  }
}, { deep: true });

watch(() => store.filterParams.sortOrder, () => {
  // 現在のURLオブジェクトを作成
  const currentUrl = new URL(window.location.href);
  // sortOrderクエリパラメータを追加または更新
  currentUrl.searchParams.set('sortOrder', store.filterParams.sortOrder);
  // 新しいURLを履歴にプッシュ
  window.history.pushState({}, '', currentUrl.toString());
  filteredData();
});

onMounted(async () => {
  // コンポーネントがマウントされたときにapiGetメソッドを呼び出します
  await apiGet();
  dataLoaded.value = true;
  const query = new URLSearchParams(window.location.search);
  query.forEach((value, key) => {
  if (Object.prototype.hasOwnProperty.call(store.filterParams, key)) {
    // 特定のキーがカンマ区切りの文字列として扱われるべきかどうかをチェック
    if (key === 'type' && typeof value === 'string' ||
        key === 'condition' && typeof value === 'string') {
      // カンマ区切りの文字列を配列に変換
      store.filterParams[key] = value.split(',');
    } else {
      // 数値に変換可能かチェック
      const numberValue = Number(value);
      // 数値に変換可能であれば数値に、そうでなければ元の文字列を使用
      store.filterParams[key] = isNaN(numberValue) ? value : numberValue;
    }
  }
  });
  store.listFilter.type = store.filterParams.type;
  filteredData();
  searchResultText.value = processFilterParams(store.filterParams);

  window.addEventListener('resize', checkWindowSize);

});

onUnmounted(() => {
      window.removeEventListener('resize', checkWindowSize);
    });

</script>
