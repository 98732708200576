/**============================================================
*   [FixHeightBox]
*   description : 高さ揃えの処理
*   揃える要素    : js-fix-height-box
*   揃える個数    : デフォルト＝すべて'all'
*               : data-each-num-sm：breakSmNumのサイズ以上で判定する個数('all' | num)
*               : data-each-num-md：breakMdNumのサイズ以上で判定する個数('all' | num)
*               : data-each-num-sm-down：breakSmDownNumのサイズ未満で判定する個数('all' | num | 1 default)
============================================================*/
export default class FixHeightBox {
  constructor(target, key, breakSmNum=767, breakMdNum=1100) {
    this.target = target;
    this.key = key;

    this.breakEachNumSm = this.target.getAttribute('data-each-num-sm') ? parseInt(this.target.getAttribute('data-each-num-sm')) : 'all';
    this.breakEachNumMd = this.target.getAttribute('data-each-num-md') ? parseInt(this.target.getAttribute('data-each-num-md')) : 'all';
    this.breakEachNumSmDown = this.target.getAttribute('data-each-num-sm-down') == 'all' ? 'all' : this.target.getAttribute('data-each-num-sm-down') ? parseInt(this.target.getAttribute('data-each-num-sm-down')) : 1;

    // 要素の高さを揃える関数
    const setEqualHeight = ()=> {
      // 比較する要素の数
      let itemsToCompare = window.innerWidth > breakMdNum ? this.breakEachNumMd : (window.innerWidth > breakSmNum ? this.breakEachNumSm : this.breakEachNumSmDown);

      const items = this.target.getElementsByClassName('js-fix-height-box__item');
      const groups = {};

      [...items].forEach(item => {
        const group = item.getAttribute('data-height-group');
        if (!groups[group]) {
          groups[group] = [];
        }
        groups[group].push(item);
      });

      // 各グループごとに高さを比較
      for (const group in groups) {
        const groupItems = groups[group];
        const groupSize = groupItems.length;
        if(itemsToCompare === 'all') itemsToCompare = groupSize;

        // 指定した個数ごとに比較し高さを揃える
        for (let i = 0; i < groupSize; i += itemsToCompare) {
          const groupSlice = groupItems.slice(i, i + itemsToCompare);
          let maxHeight = 0;

          groupSlice.forEach(item => {
            item.style.height = ''; // リセット
            const height = item.offsetHeight;
            maxHeight = Math.max(maxHeight, height);
          });

          groupSlice.forEach(item => {
            item.style.height = `${maxHeight}px`;
          });
        }
      }
    };
    // ウィンドウのリサイズ時に高さを再設定
    let resizeTimer;

    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setEqualHeight();
      }, 250); // タイマーの遅延時間（ミリ秒）
    });

    window.addEventListener('load', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        // 初回実行
        setEqualHeight();
      }, 100); // タイマーの遅延時間（ミリ秒）
    });

  }

}
