<template>
  <form
    class="c-form-search-border"
    @submit.prevent="applyFilters"
  >
    <label for="search">
      <input
        id="search"
        v-model="store.keyword"
        type="search"
        name="keyword"
        placeholder="フリーワードで探す"
      >
    </label>
    <button
      type="button"
      class="c-icon"
      @click="applyFilters()"
    >
      <i class="material-symbols-outlined u-fz--title-md">search</i>
    </button>
  </form>
</template>
<script setup>
import { onMounted } from 'vue';
import { useFilterStore } from './store';

const store = useFilterStore();

const toQueryString = (keyword) => {
  // keyword が空でなければ、エンコードして返す
  return keyword ? `keyword=${encodeURIComponent(keyword)}` : '';
}

const applyFilters = async () => {
  await store.resetFilterParams();
  await store.resetModalFilter();
  store.query = toQueryString(store.keyword);
  if(store.query) {
    store.filterParams.keyword = store.keyword;
    if (window.location.pathname !== '/kaitori/') {
      // 一覧ページ以外からのアクセスの場合、ページ全体をリダイレクト
      window.location.href = `/kaitori/?${store.query}`;
    } else {
      // すでに一覧ページにいる場合、クエリパラメータを更新
      const newUrl = `${window.location.pathname}?${store.query}`;
      window.history.pushState({}, '', newUrl);
    }
  }
}

onMounted( () => {
  store.keyword = store.filterParams.keyword;
  const setKeywordFromQuery = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const keyword = queryParams.get('keyword');
  if (keyword) {
    // ストアの keyword プロパティに設定
    store.keyword = keyword;
  }
  };
  setKeywordFromQuery();
});
</script>
