/**============================================================
*   [contactform7Customize]
*   description : お問い合わせフォームのエラー文言処理
============================================================*/
document.addEventListener('DOMContentLoaded', function() {
  const contactForm = document.querySelector('#form-section');
  if (contactForm) {
    jQuery(document).on('wpcf7invalid', function () {
      jQuery.ajax().always(function () {

        // 既存のエラー文言を削除
        if (jQuery('.error').length) {
          jQuery('.error').each(function (index, prev_error) {
            jQuery(prev_error).remove();
          });
        }

        jQuery('.wpcf7-form-control-wrap').each(function (index, el) {
          if (jQuery(el).find('.wpcf7-not-valid-tip').length) {

            // エラー文言用classを追加
            jQuery(el).find('.wpcf7-not-valid-tip').addClass('c-text-sm u-color--red error');

            // エラー文言表示位置を変更
            if (jQuery(el).prev('.c-form-item__label-set').length) {
              jQuery(el).prev('.c-form-item__label-set').find('.is-require').after(jQuery(el).find('.wpcf7-not-valid-tip'));
            }
            if (jQuery(el).parent().prev('.c-form-item__label-set').length) {
              jQuery(el).parent().prev('.c-form-item__label-set').find('.is-require').after(jQuery(el).find('.wpcf7-not-valid-tip'));
            }
            if (jQuery(el).parent().parent().prev('.c-form-item__label-set').length) {
              jQuery(el).parent().parent().prev('.c-form-item__label-set').find('.is-require').after(jQuery(el).find('.wpcf7-not-valid-tip'));
            }
            // 前の要素のエラー文言と重複していた場合
            jQuery('.wpcf7-not-valid-tip').each(function (index_ch, el_ch) {
              if (jQuery(el_ch).prev().text() == jQuery(el_ch).text()) {
                jQuery(el_ch).remove();
              }
            });
          }
          if (jQuery(el).next().is('span.c-icon.c-icon-clear')) {
            jQuery(el).find('.is-valid').last().after(jQuery(el).next());
          }
        });
        // エラー文言表示時のスクロール処理
        const position = jQuery('.wpcf7-not-valid-tip:first').closest('section').offset().top;
        const header = jQuery('#header-navigation').outerHeight();
        jQuery('html, body').animate({
          scrollTop: position - header
        }, 700);
      });
    });
    jQuery(document).ready(function () {
      jQuery('.wpcf7-form-control-wrap').each(function (index, el) {
        jQuery(el).find('.wpcf7-radio .wpcf7-list-item > input').after('<span class="c-form-radio__icon"></span>');
      });
    });
    jQuery(document).on('wpcf7mailsent', function () {
      const uploads = document.getElementsByClassName('c-form-upload');
      if (uploads) {
        [...uploads].forEach(target => {
          const inputButton = target.querySelector('.js-form-upload');
          const textArea = target.querySelector('.text');
          inputButton.value = '';
          textArea.textContent = '選択してください';
          textArea.classList.add('is-empty');
        });
      }
      jQuery('.wpcf7-not-valid-tip').hide();
    });
  //送信ボタンを押した時のみバリデーションメッセージ表示
  jQuery('.form-submit').click(function () {
    jQuery('.c-form-item__label-set').addClass('is-show');
  });

  // 送信ローダーの調整
  const submitInput = document.getElementsByClassName('wpcf7-submit');
  const submitElement = document.getElementsByClassName('form-submit');
  const submitValue = submitInput[0].value;

  submitElement[0].addEventListener('click', () => {
      submitElement[0].classList.add('wpcf7-active'); // 指定のクラスを付与する
      submitInput[0].value = '送信中…'; // 送信中の文言(*1)
  });

  document.addEventListener('wpcf7submit', () => { // Ajaxのフォーム送信が完了した場合（成功・失敗問わず）
      submitElement[0].classList.remove('wpcf7-active'); // 指定のクラスを外す
      submitInput[0].value = submitValue;
  });
  }
});
