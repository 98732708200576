<template>
  <div
    id="modal-search"
    class="p-micro-modal"
    aria-hidden="true"
  >
    <div
      class="p-micro-modal__overlay"
      tabindex="-1"
      data-micromodal-close
    >
      <div
        class="p-micro-modal__container p-nav-type-search-modal__container"
        role="dialog"
        aria-modal="true"
      >
        <button
          type="button"
          class="p-micro-modal__icon p-micro-modal__icon--close p-nav-type-search-modal__close"
          aria-label="閉じる"
          data-micromodal-close
        />
        <div class="p-micro-modal__scroller l-scroll-bar p-nav-type-search-modal__scroller">
          <div class="p-micro-modal__content p-nav-type-search-modal__content">
            <nav class="p-nav-type-search">
              <div class="p-nav-type-search__title">
                <div class="c-title-icon u-color--key">
                  <span
                    class="c-icon"
                    aria-hidden="true"
                  >
                    <i
                      class="material-symbols-outlined u-fz--title-sm--fix"
                      aria-hidden="true"
                    >search</i>
                  </span>
                  <h2 class="text c-title-sm u-weight--bold">
                    絞り込み検索
                  </h2>
                </div>
              </div>
              <div class="p-nav-type-search__body">
                <section>
                  <h3 class="c-text-md u-weight--bold">
                    車両タイプで探す
                  </h3>
                  <div class="u-mt--xxs p-nav-type-search__buttons">
                    <button
                      v-for="carType in meta.type"
                      :key="carType"
                      type="button"
                      class="c-button-search-square"
                      :class="{'is-active': store.filterParams.type.includes(carType.name) || store.modalFilter.type.includes(carType.name)}"
                      @click="updateModalType(carType.name)"
                    >
                      <span
                        class="c-icon c-icon--reflect"
                        aria-hidden="true"
                      >
                        <svg :class="'svg-icon_type_' + carType.slug + '-dims'">
                          <use :xlink:href="'/cms/wp-content/themes/unitrucks/assets/img/shared/sprite.symbol.svg#icon_type_' + carType.slug" />
                        </svg>
                      </span>
                      <span class="text">{{ carType.name }}</span>
                    </button>
                  </div>
                </section>
                <section>
                  <h3 class="c-text-md u-weight--bold">
                    メーカー／車種で探す
                  </h3>
                  <div class="u-mt--xxs p-nav-type-search__maker-row">
                    <div class="c-form-select">
                      <select
                        v-model="selectedMaker"
                        name="maker-type"
                      >
                        <option value="">
                          メーカーを選択してください
                        </option>
                        <option
                          v-for="maker in meta.maker"
                          :key="maker.name"
                          :value="maker.name"
                        >
                          {{ maker.name }}
                        </option>
                      </select>
                    </div>
                    <div class="c-form-select">
                      <select
                        v-model="store.modalFilter.makerCar"
                        name="model-type"
                      >
                        <option value="">
                          車種を選択してください
                        </option>
                        <option
                          v-for="model in availableModels"
                          :key="model"
                          :value="model"
                        >
                          {{ model }}
                        </option>
                      </select>
                    </div>
                  </div>
                </section>
                <section class="p-nav-type-search__other-col-button">
                  <h3 class="c-text-md u-weight--bold">
                    状態別で探す
                  </h3>
                  <div class="u-mt--xxs p-nav-type-search__buttons">
                    <button
                      v-for="condition in meta.condition"
                      :key="condition"
                      type="button"
                      class="c-button-search-text"
                      :class="{'is-active': store.filterParams.condition.includes(condition) || store.modalFilter.condition.includes(condition)}"
                      @click="updateCondition(condition)"
                    >
                      {{ condition }}
                    </button>
                  </div>
                </section>
                <div class="p-nav-type-search__other-row">
                  <section class="p-nav-type-search__other-col-select">
                    <h3 class="c-text-md u-weight--bold">
                      年式で探す
                    </h3>
                    <div class="u-mt--xxs">
                      <div class="c-form-select">
                        <select
                          v-model="store.modalFilter.modelYear"
                          name="maker-type"
                        >
                          <option
                            value=""
                            selected
                          >
                            年式を選択してください
                          </option>
                          <option
                            v-for="year in meta.year"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </section>
                  <section class="p-nav-type-search__other-col-select">
                    <h3 class="c-text-md u-weight--bold">
                      走行距離で探す
                    </h3>
                    <div class="u-mt--xxs">
                      <div class="c-form-select">
                        <select
                          v-model="store.modalFilter.mileage"
                          name="maker-type"
                        >
                          <option
                            :value="null"
                          >
                            距離を選択してください
                          </option>
                          <option
                            v-for="mileage in meta.mileage"
                            :key="mileage"
                            :value="mileage.mileage"
                          >
                            {{ mileage.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="p-nav-type-search__action-buttons">
                <button
                  type="button"
                  class="c-button c-button--transparent u-color--gray p-nav-type-search__button__reset"
                  @click="resetButton"
                >
                  <span class="c-text-lg u-weight--bold">リセット</span>
                </button>
                <button
                  class="c-button p-nav-type-search__button-search"
                  @click="applyFilters"
                >
                  <span
                    class="c-icon left"
                    aria-hidden="true"
                  >
                    <i
                      class="material-symbols-outlined u-fz--title-sm--fix"
                      aria-hidden="true"
                    >search</i>
                  </span>
                  検索する
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import MicroModal from 'micromodal';
import { useFilterStore } from './store';
import axios from 'axios';

const store = useFilterStore();


const selectedMaker = ref('');

const availableModels = computed(() => {
  const makers = meta.value.maker || [];
  if (selectedMaker.value === '') {
    return makers.flatMap(maker => maker.children || []);
  } else {
    const maker = makers.find(m => m.name === selectedMaker.value);
    return maker ? maker.children : [];
  }
});

// 車両タイプの変更
const updateModalType = (value) => {
  const index = store.modalFilter.type.indexOf(value);
  if (index > -1) {
    store.modalFilter.type.splice(index, 1);
  } else {
    store.modalFilter.type.push(value);
  }
};

const updateCondition = (value) => {
  const index = store.modalFilter.condition.indexOf(value);
  if (index > -1) {
    store.modalFilter.condition.splice(index, 1);
  } else {
    store.modalFilter.condition.push(value);
  }
};

const toQueryString = (params) => {
  const parts = [];

  for (const key in params) {
    let value = params[key];
    if (Array.isArray(value)) {
      // 配列の場合はカンマで結合する
      value = value.join(',');
    }
    if (value) {
      // キーと値をエンコードして配列に追加
      parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }

  return parts.join('&');
}

const resetButton = () => {
  store.reset = true;
  store.resetListFilter();
  store.resetModalFilter();
  store.resetFilterParams();
  selectedMaker.value = '';
};

const applyFilters = () => {
  store.keyword = '';
  store.filterParams.keyword = '';
  store.modalFilter.maker = selectedMaker.value;
  store.filterParams = store.modalFilter;
  store.query = toQueryString(store.filterParams);
  if (window.location.pathname !== '/kaitori/') {
    // 一覧ページ以外からのアクセスの場合、ページ全体をリダイレクト
    window.location.href = `/kaitori/?${store.query}`;
  } else {
    // すでに一覧ページにいる場合、クエリパラメータを更新
    store.modalFilter.type = store.listFilter.type;
    const newUrl = `${window.location.pathname}?${store.query}`;
    window.history.pushState({}, '', newUrl);
  }
  MicroModal.close('modal-search');
}

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  }
});

const meta = ref({});

const apiGet = async () => {
  const response = await api.get('/api-meta/');
  meta.value = response.data.meta;
  store.mileage = response.data.meta.mileage;
};

watch(selectedMaker, () => {
  store.filterParams.makerCar = '';
});

onMounted(async () => {
  await apiGet();
  store.modalFilter = store.filterParams;
  MicroModal.init({
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    onShow: ()=>{
      document.documentElement.classList.add('is-modal-opened');
    },
    onClose: ()=>{
      document.documentElement.classList.remove('is-modal-opened');
    }
  });
});
</script>
