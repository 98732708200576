/**============================================================
*   [Class] パネル型記事スライダー
============================================================*/
export default class ArchiveSlider {
  constructor(
    target,
    key,
  )
  {
    this.swiperSlide = target;
    this.swiperKey = key;
    this.swiperSlide.classList.remove('swiper-disabled');
    this.swiperSlide.classList.add('swiper-archive-'+ this.swiperKey);
    this.breakSmNum = 768;

    let swiperSlides = this.swiperSlide.getElementsByClassName('swiper-slide');
    // let slidable = swiperSlides.length > 3;
    this.swiperSlideItemNum = swiperSlides.length * 2;

    this.initSwiper = () => {
      this.mySwiper = new Swiper(this.swiperSlide, {
        slidesPerView: 1,
        loop: true,
        loopAdditionalSlides: this.swiperSlideItemNum,
        loopPreventsSlide: true,
        pagination: {
            el: '.swiper-archive-'+ key + ' .swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
          nextEl: '.swiper-archive-'+ key + '+ .swiper-button-prev + .swiper-button-next',
          prevEl: '.swiper-archive-'+ key + '+ .swiper-button-prev',
        },
        breakpoints: {
          1100: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          }
        },
      });
    };

    //6枚以上になるようにclone
    this.cloneSlide = () => {
      for(let i = swiperSlides.length / 3; i < 3; i++) {
        Object.keys(swiperSlides).forEach(element => {
          const cloneSlide = swiperSlides[element].cloneNode(true);
          cloneSlide.classList.add('slide-clone');
          this.swiperSlide.querySelector('.swiper-wrapper').append(cloneSlide);
        });
      }
    }

    //PC, SP切替時にdestroyして枚数操作
    //PC:cloneあり
    //SP:cloneなし
    this.checkSlider = () => {
      if(window.innerWidth > this.breakSmNum) {
        if(this.mySwiper) this.mySwiper.destroy(false,true);
        //3個以下の場合はcloneして挿入
        if(swiperSlides.length <= 3) {
          this.cloneSlide();
        }
        this.initSwiper();
      }else {
        if(this.mySwiper) {
          this.mySwiper.destroy(false,true);
          const cloneElem = this.swiperSlide.querySelectorAll(".slide-clone",'.swiper-archive-'+ key + ' .slide-clone');
          cloneElem.forEach(element => {
            element.remove();
          })
        }
        this.initSwiper();
      }
    }

    const mqlCenteredSlider = window.matchMedia('(min-width: ' + this.breakSmNum + 'px)');
    this.checkSlider(mqlCenteredSlider);
    mqlCenteredSlider.addEventListener('change', this.checkSlider, false);
  }
}
